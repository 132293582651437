export default function useKeyboardGlobalEvent<T>(key: string, callback) {
  function handleKeydown(event) {
    if (event.key === key) {
      callback();
    }
  }
  onMounted(() => {
    window.addEventListener('keydown', handleKeydown);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeydown);
  });
}
